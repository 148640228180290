import { FC, HTMLAttributes } from 'react';

import { ReactComponent as NoLogo } from '../../../../assets/images/agroid/logo-template.svg';
import { OrgLogo } from '../OrgLogo';
import { InvitationType } from '../../../../api/models/InvitationType';
import { UserStatusLabels } from '../../../../api/models/applications.model';

import { InviteCardWrapper, OrgCardStyled, LogoOrgNameWrapper, OrgLogoWrapper } from './styled';

export interface OrgCard extends HTMLAttributes<HTMLDivElement> {
  value: string;
  label: string;
  logo?: any;
  text: string;
}

const trimName = str => {
  if (str.length <= 50) {
    return str;
  }
  return `${str.slice(0, str.indexOf(' ', 30))}...`;
};

export const InviteCard: FC<InvitationType & { onClick: () => void }> = props => (
  <InviteCardWrapper
    key={props.organization.name}
    className={'invite-card-wrapper'}
    onClick={props.onClick}
  >
    <OrgCardStyled>
      <LogoOrgNameWrapper>
        {props.organization?.logo?.downloadUrl ? (
          <OrgLogoWrapper>
            <OrgLogo
              downloadUrl={props.organization?.logo?.downloadUrl}
              fileName={props.organization?.logo?.downloadUrl}
            />
          </OrgLogoWrapper>
        ) : (
          <NoLogo className="logo" style={{ margin: '0' }} />
        )}
        <OrgCardStyled.Title>{trimName(props.organization.name)}</OrgCardStyled.Title>
      </LogoOrgNameWrapper>
      <OrgCardStyled.Text status={props.status}>
        {UserStatusLabels[props.status]}
      </OrgCardStyled.Text>
    </OrgCardStyled>
  </InviteCardWrapper>
);
