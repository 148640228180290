import { FC, useState, useEffect } from 'react';
import { Modal, Body } from '@zendeskgarden/react-modals';
import { Skeleton } from '@zendeskgarden/react-loaders';
import { Field, Label } from '@zendeskgarden/react-forms';
import { Anchor, Button } from '@zendeskgarden/react-buttons';
import { observer } from 'mobx-react';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { Dropdown, Item, Menu, Trigger } from '@zendeskgarden/react-dropdowns';
import { ReactComponent as ChevronIcon } from '@zendeskgarden/svg-icons/src/16/chevron-down-stroke.svg';

import { OrganizationStatusLabels } from '../../../../api/models/applications.model';
import { Spacer, ModalHeader, NoData, IModalHeaderAction } from '../';
import { formatDate, formatPhone, useStore } from '../../../../utils';
import { InvitationStore } from '../../stores/invitaion.store';
import { RolesStore } from '../../stores/roles.store';

import { StyledRoleButton, StyledStatus, StyledUserName } from './styled';

type TSelectedItem = {
  value: any;
  label: string;
};
export interface IAppDetailsModalProps {
  invitationId: string;
  title: string;
  actions: IModalHeaderAction[];
  onClose: (result?: any) => void;
}

const NOITEM = { label: 'no item', value: '' };

export const AppDetailsModal: FC<IAppDetailsModalProps> = observer(
  ({ invitationId, onClose, actions, title }) => {
    const { isLoading, invitation, fetchInvitation, assignInvitationRole } = useStore(
      InvitationStore
    );
    const { roles } = useStore(RolesStore);
    const [rotated, setRotated] = useState<boolean | undefined>();
    const [selectedItem, setSelectedItem] = useState<TSelectedItem>(NOITEM);

    useEffect(() => {
      fetchInvitation(invitationId);
    }, [invitationId]);

    useEffect(() => {
      let userRole = NOITEM;
      if (invitation && invitation.role) {
        userRole = {
          value: invitation.role.id,
          label: invitation.role.name,
        };
      }
      setSelectedItem(userRole);
    }, [invitation]);

    const handleSelectRole = item => {
      const role = roles.find(r => r.id === item);
      if (role) {
        setSelectedItem({
          value: role.id,
          label: role.name,
        });
        assignInvitationRole(role.id, invitationId);
      } else {
        setSelectedItem(NOITEM);
      }
    };

    return (
      <Modal onClose={onClose}>
        <ModalHeader
          title={title}
          actions={invitation && invitation.status === 'SENT' ? actions : []}
        />
        <Body>
          {isLoading ? (
            <>
              <Skeleton height="24px" />
              <Skeleton height="12px" />
              <Skeleton height="12px" />
              <Skeleton height="12px" />
              <Skeleton height="12px" />
            </>
          ) : invitation ? (
            <>
              <StyledUserName data-id={invitation.userId} isBold>
                {invitation.userName}
              </StyledUserName>
              <Spacer />
              <Grid>
                <Row>
                  <Col size={5}>
                    <Field>
                      <Label>Статус</Label>
                    </Field>
                  </Col>
                  <Col>
                    <StyledStatus status={invitation.status}>
                      {Boolean(invitation.status) && OrganizationStatusLabels[invitation.status]}
                    </StyledStatus>
                  </Col>
                </Row>
                <Row>
                  <Col size={5}>
                    <Field>
                      <Label>Роль</Label>
                    </Field>
                  </Col>
                  <Col>
                    {selectedItem.label === 'Владелец' || invitation.status !== 'SENT' ? (
                      <>{selectedItem.label}</>
                    ) : (
                      <Dropdown
                        selectedItem={selectedItem.value}
                        onSelect={handleSelectRole}
                        onStateChange={options => 'isOpen' in options && setRotated(options.isOpen)}
                      >
                        <Trigger>
                          <StyledRoleButton>
                            {selectedItem.label}
                            <Button.EndIcon isRotated={rotated}>
                              <ChevronIcon />
                            </Button.EndIcon>
                          </StyledRoleButton>
                        </Trigger>
                        <Menu isCompact>
                          {roles
                            .filter(r => r.name !== 'Владелец')
                            .map(({ id, name }) => (
                              <Item value={id} key={id}>
                                {name}
                              </Item>
                            ))}
                        </Menu>
                      </Dropdown>
                    )}
                  </Col>
                </Row>
                {Boolean(invitation.phone) && (
                  <Row>
                    <Col size={5}>
                      <Field>
                        <Label>Телефон</Label>
                      </Field>
                    </Col>
                    <Col>{formatPhone(invitation.phone)}</Col>
                  </Row>
                )}
                <Row>
                  <Col size={5}>
                    <Field>
                      <Label>E-mail</Label>
                    </Field>
                  </Col>
                  <Col style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    <Anchor href={`mailto:${invitation.userEmail}`} style={{ color: 'inherit' }}>
                      {invitation.userEmail}
                    </Anchor>
                  </Col>
                </Row>
                <Row>
                  <Col size={5}>
                    <Field>
                      <Label>Дата отправки</Label>
                    </Field>
                  </Col>
                  <Col>
                    <>{formatDate(invitation.createdDate, 'dd.MM.yyyy')}</>
                  </Col>
                </Row>
              </Grid>
              <Spacer />
              <Grid>
                <Row>
                  <Col size={1}></Col>
                  <Col>
                    <Button isPrimary size="large" isStretched onClick={onClose}>
                      Закрыть
                    </Button>
                  </Col>
                  <Col size={1}></Col>
                </Row>
              </Grid>
            </>
          ) : (
            <NoData message="Данные приглашения не найдены" />
          )}
        </Body>
      </Modal>
    );
  }
);
