import { TypeUserRole } from './user.model';

export enum ApplicationStatus {
  SENT = 'SENT',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  CANCELLED = 'CANCELLED',
}

export const OrganizationStatusLabels = {
  [ApplicationStatus.SENT]: 'Отправлено',
  [ApplicationStatus.ACCEPTED]: 'Принято',
  [ApplicationStatus.DECLINED]: 'Отклонено',
  [ApplicationStatus.CANCELLED]: 'Отменено',
};

export const UserStatusLabels = {
  [ApplicationStatus.SENT]: 'Вас приглашают',
  [ApplicationStatus.ACCEPTED]: 'Принято',
  [ApplicationStatus.DECLINED]: 'Отклонено',
  [ApplicationStatus.CANCELLED]: 'Отозвано',
}

export enum ApplicationInviteType {
  INVITE = 'invite',
  ACCEPT = 'accept',
  DECLINE = 'decline',
}

export type TypeApplication = {
  type: 'APPLICATION' | 'INVITATION';
  status: ApplicationStatus;
  comment: string;
  updatedDate: string;
  creationDate: string;
  applicationId: string;
  createdDate: string;
  userId: string;
  userName: string;
  userEmail?: string;
  userPhone?: string;
  phone?: any;
  role?: TypeUserRole;
  createdBy: {
    userName: string;
    userId: string;
  };
  employeeRole?: string;
};

// Special type for new applications
export type TypeApplication2 = {
  type: 'APPLICATION' | 'INVITATION';
  status: ApplicationStatus;
  comment: string;
  createdWhen: string;
  applicationId: string;
  organization: {
    id: string;
    inn: string;
    name: string;
  };
};
