import { FC, useEffect, useState, useMemo, useRef } from 'react';
import { observer } from 'mobx-react';
import { isEmpty, throttle } from 'lodash';
import { isMobile, isTablet } from 'react-device-detect';
import { Well } from '@zendeskgarden/react-notifications';
import { Skeleton } from '@zendeskgarden/react-loaders';
import { Button, IconButton } from '@zendeskgarden/react-buttons';
import { ReactComponent as PlusIcon } from '@zendeskgarden/svg-icons/src/16/plus-fill.svg';

import { useStore } from '../../../../../../utils';
import { ScoutsStore } from '../../../../stores';
import { EmptyType, FetchType } from '../../../../../../api/models/contractor.model';
import SearchIcon from '../../../../../../assets/images/agroid/colored/search.svg';
import NoDataIcon from '../../../../../../assets/images/agroid/colored/noDataIcon.svg';
import { ContractorFilter, ContractorCard, ContractorAddModal } from '../../components';
import {
  Layout,
  SidebarLayout,
  SidebarMenu,
  WellHeader,
  CardsList,
  InfinityScroll,
} from '../../../../components';
import { useContractorsMenu } from '../../useContractorsMenu';
import {
  WellStyled,
  WellFilterStyled,
  NoDataPlaceholder,
  StyledNoDataIcon,
  NoDataTitle,
} from '../styled';

export const ContractorsPage: FC = observer(() => {
  const {
    fetchScouts,
    setScoutName,
    scouts,
    totalPages,
    setPage,
    page,
    scoutName,
    emptyType,
    isLoading,
    isLoaded,
    clearScouts,
  } = useStore(ScoutsStore);
  const [visible, setVisible] = useState(false);
  const menuItems = useContractorsMenu();

  const actions = useMemo(
    () => [
      {
        caption: isMobile && !isTablet ? false : 'Добавить',
        component: isMobile && !isTablet ? IconButton : Button,
        icon: isMobile && !isTablet && PlusIcon,
        dataTestIdAction: 'scout-add-action',
        componentProps: {
          isPrimary: true,
          isBasic: false,
          onClick() {
            setVisible(true);
          },
        },
      },
    ],
    []
  );

  const throttledFilterRequest = useRef(throttle(() => fetchScouts(), 1000));

  useEffect(() => {
    throttledFilterRequest.current();
  }, [scoutName]);

  useEffect(() => {
    return () => {
      clearScouts();
    };
  }, []);

  const handleChange = (value: string) => {
    setScoutName(value);
  };

  const handleClear = () => {
    setScoutName('');
  };

  const renderContent = () => {
    if (isLoading || !isLoaded) {
      return (
        <Well>
          <Skeleton height="12px" />
          <Skeleton height="12px" />
        </Well>
      );
    }

    if (!isEmpty(scouts)) {
      return (
        <InfinityScroll
          page={page}
          totalPages={totalPages}
          scrollOffset={100}
          onScroll={() => fetchScouts(FetchType.UPDATE)}
          onPageChange={() => setPage(page + 1)}
        >
          <CardsList
            list={scouts.map(scout => ({
              ...scout,
            }))}
            card={ContractorCard}
            cardProps={{
              padding: 'ssm',
            }}
            cardGridProps={{
              gridTemplateColumnsFor1024: 'repeat(3, 1fr)',
            }}
            dataTestId="scouts"
          />
        </InfinityScroll>
      );
    }

    return (
      <NoDataPlaceholder className="with-filter">
        <StyledNoDataIcon src={emptyType === EmptyType.LIST ? NoDataIcon : SearchIcon} />
        <NoDataTitle>
          {emptyType === EmptyType.LIST
            ? 'Здесь будут отображаться ваши контрагенты'
            : `Контрагент ${scoutName} не найден. Попробуйте изменить условия поиска.`}
        </NoDataTitle>
      </NoDataPlaceholder>
    );
  };

  return (
    <>
      <SidebarLayout>
        <SidebarMenu items={menuItems} />
        <Layout>
          <WellStyled>
            <WellHeader title="Список контрагентов" actions={actions} dataTestId="scouts" />
            <WellFilterStyled>
              <ContractorFilter
                onChange={handleChange}
                onClear={handleClear}
                value={scoutName}
                dataTestId="scouts"
              />
            </WellFilterStyled>
          </WellStyled>
          {renderContent()}
        </Layout>
      </SidebarLayout>

      {visible && <ContractorAddModal onClose={() => setVisible(false)} />}
    </>
  );
});
