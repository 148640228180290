import { TypeApiRoute } from '../../../models/type.api.request';

type TypeRequest = {
  roleId: string;
  userIds: string[];
};

type TypeResponse = {};

export const assignRoleToScout: TypeApiRoute & { request: TypeRequest; response: TypeResponse } = {
  url: '/api/scouting/contractors/assignRoleToScout',
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
