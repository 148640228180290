import React, { FC, HTMLAttributes, useMemo, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Anchor, IconButton } from '@zendeskgarden/react-buttons';
import { Button } from '@farmlink/farmik-ui';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { ReactComponent as PlusIcon } from '@zendeskgarden/svg-icons/src/16/plus-fill.svg';

import { useStore } from '../../../../utils';
import { CheckAccessStore, RolesStore } from '../../stores';
import {
  AccessRuleVisibility,
  AccessRuleUserActionName,
  EAccessRuleScoutingName,
} from '../../types';
import { WellHeader, RoleForm, RoleUsers, UserToRoleModal, DelRoleModal } from '../../components';
import { ProfileStore } from '../../../auth/stores/profile.store';

import { ReactComponent as ArrowSvg } from './assets/arrow.svg';
import {
  DeleteButtonBlock,
  DeleteButtonWrapper,
  DeleteRoleDisclaimer,
  RoleBlock,
  RoleUserBlock,
  UsersBlock,
} from './styled';

export type RolePageProps = HTMLAttributes<HTMLDivElement>;

export const RolePage: FC<RolePageProps> = observer(() => {
  const { orgId, roleId } = useParams<{ orgId: string; roleId: string }>();
  const [visible, setVisible] = useState(false);
  const { roleSettings, fetchRoleSettings, modules, defaultRole, fetchModulesAll } = useStore(
    RolesStore
  );
  const { user } = useStore(ProfileStore);
  const { getUserHasAccessRule } = useStore(CheckAccessStore);

  const [roleActions, setRoleActions] = useState([]);
  const [showDeleteRoleModal, setShowDeleteRoleModal] = useState(false);
  const [isUsersBlockOpen, setIsUsersBlockOpen] = useState(!isMobile);

  useEffect(() => {
    fetchModulesAll(orgId);
    if (roleId) {
      fetchRoleSettings(roleId);
    }
  }, [orgId, roleId]);

  const userActions = useMemo(
    () => [
      {
        caption: isMobileOnly ? false : 'Добавить',
        component: isMobileOnly ? IconButton : Anchor,
        icon: isMobileOnly ? PlusIcon : ArrowSvg,
        positionIcon: 'right',
        dataTestIdAction: 'role-page-user-add-action',
        componentProps: {
          isPrimary: true,
          isBasic: false,
          onClick() {
            setVisible(true);
          },
        },
        mode: AccessRuleVisibility.Hide,
        actionName: AccessRuleUserActionName.editEmployee,
      },
    ],
    []
  );

  const isDisabledAction = (action: any) => {
    if (roleSettings.isOwner === undefined) {
      return roleSettings.isOwner;
    }

    if (roleSettings.isOwner) {
      return true;
    }

    if (action.isScoutForUser) {
      return true;
    }

    if (getUserHasAccessRule(AccessRuleUserActionName.editRole, true)) {
      return false;
    }

    if (!getUserHasAccessRule(AccessRuleUserActionName.editRole, true)) {
      return true;
    }

    return roleSettings.isOwner;
  };

  return (
    <>
      <RoleUserBlock>
        <RoleBlock className={'role-block'}>
          <WellHeader
            title="Настройки роли"
            actions={roleActions}
            className="role-block-well-header"
            dataTestId="role-page-role-block"
          />
          {Boolean(roleSettings.id) && (
            <>
              <RoleForm
                roleId={roleId}
                modules={modules}
                roleSettings={roleSettings}
                onChange={setRoleActions}
                isDisabledAction={isDisabledAction}
              />
              {!roleSettings?.isOwner && !roleSettings?.isDefault && (
                <DeleteButtonBlock>
                  <DeleteButtonWrapper>
                    <Button
                      alignment="center"
                      color={'secondary'}
                      type="button"
                      onClick={() => setShowDeleteRoleModal(true)}
                      dataTestId="delete-role"
                    >
                      Удалить роль
                    </Button>
                  </DeleteButtonWrapper>
                  <DeleteRoleDisclaimer>
                    При удалении, всем сотрудникам этой роли будет назначена роль «
                    {defaultRole?.name || 'Нет данных'}»
                  </DeleteRoleDisclaimer>
                </DeleteButtonBlock>
              )}
            </>
          )}
        </RoleBlock>
        <UsersBlock className="users-block">
          <WellHeader
            title="Сотрудники"
            actions={roleSettings.isOwner ? [] : userActions}
            hasAccordeon={true}
            onToggleAccordeon={() => {
              setIsUsersBlockOpen(!isUsersBlockOpen);
            }}
            dataTestId="role-page-users-block"
          />
          {isUsersBlockOpen && <RoleUsers orgId={orgId} roleId={roleId} />}
        </UsersBlock>
      </RoleUserBlock>

      {visible && (
        <UserToRoleModal orgId={orgId} roleId={roleId} onClose={() => setVisible(false)} />
      )}
      {showDeleteRoleModal && (
        <DelRoleModal
          roleId={roleId as string}
          anotherRoleName={defaultRole?.name || 'Нет данных'}
          onClose={() => setShowDeleteRoleModal(false)}
        />
      )}
    </>
  );
});
